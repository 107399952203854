/**
 * @created by Zheko Hristov on 11/08/2021
 * @last-updated by Zheko Hristov on 19/08/2021
 *
 * Alerts scripts
 **/

const alertsScripts = (function () {
    'use strict';

    /**
     * @created by Zheko Hristov on 19/08/2021
     * @last-updated by Zheko Hristov on 19/08/2021
     *
     * Init function
     **/

    // function for enable timout for hiding the alert containers
    let clerAlertContainersTimeOutFunc;
    function clearAlertsContainersAfterTime() {
        clerAlertContainersTimeOutFunc = setTimeout(function () {
            hideAlerts();
        }, 5000);
    }

    function init() {
        // clear timeout, because when change pages the timeout continue runing
        clearTimeout(clerAlertContainersTimeOutFunc);

        // show alerts from the html with some delay
        const alertsContainers = document.querySelectorAll('.alerts__container--inner');
        const alertsContainersLength = alertsContainers.length;
        for (let i = 0; i < alertsContainersLength; i++) {
            setTimeout(function () {
                alertsContainers[i].classList.add('showed');
            }, 250 * i);
        }

        // hide the alerts after some time
        clearAlertsContainersAfterTime();

        // hide specified alert container by click on X icon
        document.querySelector('.alerts__container--outer').addEventListener('click', function (e) {
            if (e.target.classList.contains('fa-times')) {
                let alertInnerContainer = e.target.parentNode.parentNode;
                alertInnerContainer.classList.remove('showed');
                setTimeout(function () {
                    alertInnerContainer.parentNode.removeChild(alertInnerContainer);
                }, 250);
            }
        });
    }

    /**
     * @created by Zheko Hristov on 19/08/2021
     * @last-updated by Zheko Hristov on 24/08/2021
     *
     * Hide the alerts function
     **/
    function hideAlerts() {
        const alertsContainerOuter = document.querySelector('.alerts__container--outer');
        const alertsContainers = document.querySelectorAll('.alerts__container--inner');
        const alertsContainersLength = alertsContainers.length;

        for (let i = 0; i < alertsContainersLength; i++) {
            alertsContainers[i].style.pointerEvents = 'none';
            setTimeout(function () {
                alertsContainers[i].classList.remove('showed');
            }, 250 * i);
        }

        setTimeout(function () {
            for (let i = 0; i < alertsContainersLength; i++) {
                if (alertsContainerOuter.children.length) {
                    alertsContainerOuter.removeChild(alertsContainers[i]);
                }
            }
        }, 250 * alertsContainersLength);
    }

    /**
     * @created by Zheko Hristov on 19/08/2021
     * @last-updated by Zheko Hristov on 25/08/2021
     *
     * Render alert function
     **/

    function renderAlert(message, type) {
        hideAlerts();
        setTimeout(function () {
            // clear timeout function after some delay, because renderAlert function is invoked in other files - for example favourites,
            // and may be executed before alerts init method, in which the clerAlertContainersTimeOutFunc function is initialzed
            // and the value of clerAlertContainersTimeOutFunc is undefined
            clearTimeout(clerAlertContainersTimeOutFunc);

            const alertContainerTemplate = `<div class="alerts__container--inner ${type}">
                <div class="alerts__container--close">
                    <i class="fas fa-times"></i>
                </div>
                <div class="alerts__container--message">
                    <i class="fas fa-check-circle fa-2x fa-fw"></i>
                    <i class="fas fa-exclamation-circle fa-2x fa-fw"></i>
                    <i class="fas fa-info-circle fa-2x fa-fw"></i>
                    <i class="fas fa-exclamation-triangle fa-2x fa-fw"></i>
                    ${message}
                </div>
            </div>`;

            document
                .querySelector('.alerts__container--outer')
                .insertAdjacentHTML('afterbegin', alertContainerTemplate);
            setTimeout(function () {
                document.querySelector('.alerts__container--inner').classList.add('showed');
                clearAlertsContainersAfterTime();
            }, 250);
        }, 250);
    }

    /**
     * @created by Zheko Hristov on 24/08/2021
     * @last-updated by Zheko Hristov on 24/08/2021
     *
     * Clear alert containers function for browser back button issue
     **/

    function clearAlertsBrowserBack() {
        while (document.querySelector('.alerts__container--outer').lastChild) {
            document
                .querySelector('.alerts__container--outer')
                .removeChild(document.querySelector('.alerts__container--outer').lastChild);
        }
    }

    return {
        init: init,
        renderAlert: renderAlert,
        clearAlertsBrowserBack: clearAlertsBrowserBack,
    };
})();

export default alertsScripts;
