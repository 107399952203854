import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 21/08/2024
 *
 * Shopping cart scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        shoppingCartInit();

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 21/08/2024
         *
         * Add to shopping cart scripts
         **/

        $('.js-add-to-cart').on('click', function (e) {
            e.preventDefault();
            const thisButton = $(this);
            const thisButtonParentContainer = thisButton.closest('.js-cart-outer');
            const thisProductMainImageSrc = thisButton.attr('data-main-image-src');

            const productId = thisButtonParentContainer.attr('data-product-id');
            console.log(productId);

            const url = thisButton.data('url');
            console.log(url)

            $.ajax({
                type: 'POST',
                url: url,
                data: {
                    product_id: productId,
                    quantity: 1,
                },
                dataType: 'json',
            })
            .done(function (json) {
                console.log(json.message);

                successAddToShoppingCart(
                    thisProductMainImageSrc,
                    json.message || 'Добавихте продукт в количка',
                    json.products_count,
                    json.total_sum
                );
            })
            .fail(function (xhr, status, error) {
                let errors = ['Провалена заявка'];
                try {
                    errors = $.parseJSON(xhr.responseText).errors;
                } catch (error) {
                    console.error('Error while parsing errors response');
                    console.error(error);
                    failAddToShoppingCart(errors[0]);
                }

                failAddToShoppingCart(errors[0]);
            });
        });

        // success add to shopping cart function
        function successAddToShoppingCart(
            imageSrc,
            message,
            updatedCartItemsCount,
            updatedCartItemsTotalPrice
        ) {
            $('.product__container--box-outer').removeClass('showed');
            setTimeout(function () {
                $('.product__container--box-side.front > span').css({
                    'background-image': 'url("' + imageSrc + '")',
                });
                $('.product__container--box-outer').addClass('showed');
                setTimeout(function () {
                    // update shopping cart items count and total price with the new updated value
                    setTimeout(function () {
                        Alerts.renderAlert(message, 'success');
                        updateShoppingCartCounterAndTotalPrice(
                            updatedCartItemsCount,
                            updatedCartItemsTotalPrice
                        );
                    }, 500);
                }, 500);
            }, 100);
        }

        // fail to add to shopping cart function
        function failAddToShoppingCart(message) {
            Alerts.renderAlert(message, 'error');
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 09/10/2024
         *
         * Remove from shopping cart scripts
         **/

        $('.js-remove-from-cart').on('click', function (e) {
            e.preventDefault();
            const thisButton = $(this);
            const thisButtonParentContainer = thisButton.closest('.js-cart-outer');

            const productSingleContainer = thisButton.closest('.cart-products__container--single');

            const productId = thisButtonParentContainer.attr('data-product-id');
            console.log(productId);

            const url = thisButtonParentContainer.attr('data-url');

            $.ajax({
                type: 'DELETE',
                url: url,
                data: {
                    product_id: productId,
                },
                dataType: 'json',
            })
            .done(function (json) {
                successRemoveFromShoppingCart(
                    productSingleContainer,
                    json.message || 'Премахнахте продукт от количка',
                    json.products_count,
                    json.total_sum
                );
            })
            .fail(function (xhr, status, error) {
                let errors = ['Възникна грешка при премахването от количка'];

                try {
                    errors = $.parseJSON(xhr.responseText).errors;
                } catch (error) {
                    console.error('Error while parsing errors response');
                    console.error(error);
                }

                failRemoveFromShoppingCart(errors[0]);
            });
        });

        // success remove from shopping cart function
        function successRemoveFromShoppingCart(
            container,
            message,
            updatedCartItemsCount,
            updatedCartItemsTotalPrice
        ) {
            Alerts.renderAlert(message, 'info');
            // update shopping cart items count and total price with the new updated value
            updateShoppingCartCounterAndTotalPrice(
                updatedCartItemsCount,
                updatedCartItemsTotalPrice
            );
            // remove item from the page
            container.remove();
            if ($('.cart-products__container--single').length < 1) {
                $('.cart-order__container--outer').remove();
                $('.container--no-products').addClass('showed');
            }
            // if we have removed item with sttaus message 'out of stock', remove the 'out of stock' message in the top container
            if (
                $('.cart-order__container--main').find('.cart__text--product-out-of-stock').length === 0
            ) {
                $('.cart__text--product-out-of-stock.top-page__container').remove();
            }
        }

        // fail to remove from shopping cart function
        function failRemoveFromShoppingCart(message) {
            Alerts.renderAlert(message, 'error');
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 21/08/2024
         *
         * Update shopping cart items quantity scripts
         **/

        // was on input
        $('.js-update-cart-item-quantity').on('input', function () {
            const thisInput = $(this);
            const thisInputValue = parseInt(thisInput.val());
            const thisInputParentItemContainer = thisInput.closest(
                '.cart-products__container--single'
            );
            const thisInputParentQuantityContainer = thisInput.closest(
                '.cart__container--quantity'
            );

            const thisInputInitialValue = parseInt(
                thisInputParentQuantityContainer.attr('data-item-initial-quantity')
            );
            const thisInutAvalableValue = parseInt(
                thisInputParentQuantityContainer.attr('data-item-available-quantity')
            );

            if (isNaN(thisInputValue)) {
                thisInput.val(thisInputInitialValue);
            } else {
                if (thisInputValue > thisInutAvalableValue) {
                    thisInput.val(thisInutAvalableValue);
                }

                if (thisInputValue < 1) {
                    thisInput.val(1);
                }

                thisInput.val(Math.round(thisInput.val()));
            }

            const productQuantity = thisInput.val();
            const thisInputParentContainer = thisInput.closest('.js-cart-outer');
            const productId = thisInputParentContainer.attr('data-product-id');
            console.log(productId);
            console.log(productQuantity);

            const url = thisInput.data('url');

            $.ajax({
                type: 'POST',
                url: url,
                data: {
                    product_id: productId,
                    quantity: productQuantity,
                },
                dataType: 'json',
            })
            .done(function (json) {
                console.log(json.message);

                successUpdateItemQuantityShoppingCart(
                    thisInputParentItemContainer,
                    json.message || 'Променихте количеството на продукт',
                    json.products_count,
                    json.total_sum
                );
            })
            .fail(function (xhr, status, error) {
                let response = { errors: ['Възникна грешка'] };

                try {
                    response = $.parseJSON(xhr.responseText);
                    response.errors ||= ['Възникна грешка'];
                } catch (error) {
                    console.error('Error while parsing errors response');
                    console.error(error);
                }

                if (response.quantity != undefined) {
                    thisInput.val(response.quantity);
                } else {
                    setTimeout(function () {
                        location.reload();
                    }, 800);
                }

                failUpdateItemQuantityShoppingCart(
                    thisInputParentItemContainer,
                    response.errors[0],
                    response.products_count,
                    response.total_sum
                );
            });
        });

        // success update shopping cart item quantity function
        function successUpdateItemQuantityShoppingCart(
            itemParentContainer,
            message,
            updatedCartItemsCount,
            updatedCartItemsTotalPrice
        ) {
            Alerts.renderAlert(message, 'info');
            updateShoppingCartCounterAndTotalPrice(
                updatedCartItemsCount,
                updatedCartItemsTotalPrice
            );

            calculateEachItemInCartTotalPrice(itemParentContainer);
        }

        // fail update shopping cart item quantity function
        function failUpdateItemQuantityShoppingCart(
            itemParentContainer,
            messages,
            updatedCartItemsCount,
            updatedCartItemsTotalPrice
        ) {
            Alerts.renderAlert(messages, 'error');

            calculateEachItemInCartTotalPrice(itemParentContainer);

            updateShoppingCartCounterAndTotalPrice(
                updatedCartItemsCount,
                updatedCartItemsTotalPrice
            );
        }

        // increase item quantity
        $('.js-decrease-item-quantity').on('click', function () {
            const thisButton = $(this);
            const thisSiblingsInput = thisButton.siblings('.js-update-cart-item-quantity');
            const siblingInputValue = parseInt(thisSiblingsInput.val());
            if (siblingInputValue > 1) {
                thisSiblingsInput.val(siblingInputValue - 1);
                thisSiblingsInput.trigger('input');
            }
        });

        // decrease item quantity
        $('.js-increase-item-quantity').on('click', function () {
            const thisButton = $(this);
            const thisSiblingsInput = thisButton.siblings('.js-update-cart-item-quantity');
            let siblingInputValue = parseInt(thisSiblingsInput.val());
            if (siblingInputValue < 200) {
                thisSiblingsInput.val(siblingInputValue + 1);
                thisSiblingsInput.trigger('input');
            }
        });

        // calculate each single item total price
        function calculateEachItemInCartTotalPrice(itemContainer) {
            const thisItemUnitPrice = parseFloat(
                itemContainer.find('.cart__text--item-unit-price').text()
            );
            const thisItemQuantity = itemContainer.find('.js-update-cart-item-quantity').val();
            const thisItemTotalPrice = thisItemUnitPrice * thisItemQuantity;
            itemContainer
                .find('.cart__text--item-total-price')
                .text(roundDecimals(thisItemTotalPrice, 2));
        }

        // round decimals function
        function roundDecimals(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 21/08/2024
         *
         * Shopping cart counter and total price scripts
         **/

        // function for getting the shopping cart data
        function shoppingCartInit() {
            // request for getting shopping cart count here

            const url = '/shopping-cart/counter';
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'json',
            })
            .done(function (json) {
                updateShoppingCartCounterAndTotalPrice(json.products_count, json.total_sum);
            })
            .fail(function (xhr, status, error) {
                console.error(error);
            });
        }

        // // function for updating shopping cart counter and total price
        function updateShoppingCartCounterAndTotalPrice(shoppingCartCount, shoppingCartTotalPrice) {
            const headerShoppingCartTextCounter = $('.header__container--cart').find('.fas span');
            const shoppingCartTextTotalPrice = $('.cart__text--price');

            let displayCounterText = '0';
            let displayTotalPriceText = '0.00';
            headerShoppingCartTextCounter.css({ display: 'none' });
            if (shoppingCartCount > 0) {
                headerShoppingCartTextCounter.css({ display: 'flex' });
                displayCounterText = shoppingCartCount;
                if (shoppingCartCount > 99) {
                    displayCounterText = '99+';
                }

                displayTotalPriceText = shoppingCartTotalPrice;
            }

            headerShoppingCartTextCounter.text(displayCounterText);
            shoppingCartTextTotalPrice.text(displayTotalPriceText);
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 09/10/2024
         *
         * Submit new order form
         **/
        
        // $('.js-place-new-order').on('click', function () {
        //     $('.js-new-order-form').trigger('submit');
        // });
    })();
});