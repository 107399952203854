import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 21/08/2024
 *
 * Favourites site scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        favouritesInit();

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 23/08/2024
         *
         * Add to favourites scripts
         **/

        $('.js-add-to-favourites').on('click', function (e) {
            e.preventDefault();
            const thisButton = $(this);
            const thisButtonParentContainer = thisButton.parent('.js-favourites-outer');
            const productId = thisButtonParentContainer.attr('data-product-id');
            const url = thisButton.data('url');

            $.ajax({
                type: 'POST',
                url: url,
                data: {
                    id: productId,
                },
                dataType: 'json',
            })
            .done(function (json) {
                successAddToFavourites(
                    productId,
                    json.counter,
                    json.message || 'Добавихте продукт в любими'
                );
            })
            .fail(function (xhr, status, error) {
                let errors = ['Възникна грешка при добавянето в любими'];

                try {
                    errors = $.parseJSON(xhr.responseText).errors;
                } catch (error) {
                    console.error('Error while parsing errors response');
                    console.error(error);
                }

                failAddToFavourites(errors[0]);
            });
        });

        // success add to favourites function
        function successAddToFavourites(id, updatedFavouritesCount, message) {
            Alerts.renderAlert(message, 'success');
            // mark product as favoutited
            markProductAsFavourite(id);
            // update favourite items count with the new updated value
            updateFavouritesCounter(updatedFavouritesCount);
        }

        // fail to add to favourites function
        function failAddToFavourites(message) {
            Alerts.renderAlert(message, 'error');
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 23/08/2024
         *
         * Remove from favourites scripts
         **/

        $('.js-remove-from-favourites').on('click', function (e) {
            e.preventDefault();
            const thisButton = $(this);
            let url = thisButton.data('url');
            const thisButtonParentContainer = thisButton.parent('.js-favourites-outer');
            const checkIfIsFavouritesPage = thisButton.closest('.favourites-page').length
                ? true
                : false;

            let productSingleContainer;
            if (checkIfIsFavouritesPage) {
                productSingleContainer = thisButton.closest('a');
            }
            const productId = thisButtonParentContainer.attr('data-product-id');

            $.ajax({
                type: 'DELETE',
                url: url,
                data: {
                    id: productId,
                },
                dataType: 'json',
            })
            .done(function (json) {
                successRemoveFromFavourites(
                    productId,
                    productSingleContainer,
                    json.counter,
                    json.message || 'Премахнахте продукт от любими'
                );
            })
            .fail(function (xhr, status, error) {
                let errors = ['Възникна грешка при премахването от любими'];

                try {
                    errors = $.parseJSON(xhr.responseText).errors;
                } catch (error) {
                    console.error('Error while parsing errors response');
                    console.error(error);
                }

                failRemoveFromFavourites(errors[0]);
            });
        });

        // success remove from favourites function
        function successRemoveFromFavourites(id, container, updatedFavouritesCount, message) {
            Alerts.renderAlert(message, 'info');
            // unmark product as favoutited
            unmarkProductAsFavourite(id);
            // update favourite items count with the new updated value
            updateFavouritesCounter(updatedFavouritesCount);
            // remove item from the page, if we are on the favourites page
            if (container) {
                container.remove();
                if ($('.favourites-page').find('a').length < 1) {
                    $('.home-products__container--inner.favourites-page').remove();
                    $('.container--no-products').addClass('showed');
                }
            }
        }

        // fail to remove from favourites function
        function failRemoveFromFavourites(message) {
            Alerts.renderAlert(message, 'error');
        }

        /**
         * @created by Zheko Hristov on 21/08/2024
         * @updated by Zheko Hristov on 21/08/2024
         *
         * Favourites counter and mark scripts
         **/

        // function for getting the favourites data
        function favouritesInit() {
            // request for getting favouries count here
            const url = '/favourites/list';
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'json',
            })
            .done(function (json) {
                const productIds = json.ids;
                updateFavouritesCounter(json.ids.length);

                for (let i = 0; i < productIds.length; i++) {
                    markProductAsFavourite(productIds[i]);
                }
            })
            .fail(function (xhr, status, error) {
                console.error(error);
            });
        }

        // function for marking as favourite
        function markProductAsFavourite(id) {
            $('.js-favourites-outer[data-product-id="' + id + '"]').addClass('favourite-item');
        }

        // function for unmarking as favourite
        function unmarkProductAsFavourite(id) {
            $('.js-favourites-outer[data-product-id="' + id + '"]').removeClass('favourite-item');
        }

        // function for updating favourites counter
        function updateFavouritesCounter(favouritesCount) {
            const headerFavouritesTextCounter = $('.header__container--favourites').find('span');
            let displayCounterText = '0';
            headerFavouritesTextCounter.css({ display: 'none' });
            if (favouritesCount > 0) {
                headerFavouritesTextCounter.css({ display: 'flex' });
                displayCounterText = favouritesCount;
                if (favouritesCount > 99) {
                    displayCounterText = '99+';
                }
            }

            headerFavouritesTextCounter.text(displayCounterText);
        }
    })();
});
