let liveChatScripts = (function($) {
  function init(){
  	if(typeof LiveChatWidget !== 'undefined'){
  		LiveChatWidget.init();
  	}
  }

  function destroy(){
  	if(typeof LiveChatWidget !== 'undefined'){
  		LiveChatWidget.call('destroy');
  	}
  }

  return {
    init: init,
    destroy: destroy
  };

})(jQuery);

export default liveChatScripts;