var productIndexScripts = (function($) {

	function init(){
		bindAutoSubmitFilterForm();
	}

	function bindAutoSubmitFilterForm(){
		$(".js-auto-submit").change(function() {
		  $(this).closest('form').submit();
		});
	}

  return {
    init: init
  };

})(jQuery);

export default productIndexScripts;