import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 17/08/2021
 *
 * Order scripts
 **/

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';
        /**
         * @created by Zheko Hristov on 17/08/2021
         * @last-updated by Zheko Hristov on 26/08/2024
         *
         * Form order scripts
         **/

        const colorSelect = document.querySelector('.js-order-color-select');

        if (colorSelect) {
            const bindSpamProtection = function bindSpamProtection() {
                if (colorSelect !== undefined) {
                    colorSelect.value = 'green';
                }
            };
    
            bindSpamProtection();
        }
    })();
});
