import Cookies from 'vanilla-cookieconsent/dist/cookieconsent';

var cookies = (function($) {
  function init(){
    askCookiesPermission();
  }

  function destroy(){
    // Remove cc consent
    if ($('#cc--main').length > 0) {
      $('#cc--main').remove();
      $('html').removeClass('show--consent force--consent show--settings');
      return;
    }
  }

  function askCookiesPermission(){
    const whitelistedPathnames = [
    '/' + encodeURIComponent('общи-условия'), 
    '/' + encodeURIComponent('поверителност'), 
    '/' + encodeURIComponent('контакти')];

    const forceConsent = !whitelistedPathnames.includes(location.pathname);
  
    if (!forceConsent && location.hash == '#cc') {
      return;
    }
    // obtain plugin
    let cc = initCookieConsent();

    const privacyPolicyHref = '/поверителност#cc';
    const contactsHref = '/контакти#cc';
    const cookiesHref = '/поверителност#cc';

    // run plugin with your configuration
    cc.run({
      current_lang: 'bg',
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false
      mode: 'opt-out',
      force_consent: forceConsent,
      hide_from_bots: true,
      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: null                     // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: true,                   // default: true
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      // revision: 0,                            // default: 0

      onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
        if (cookie.categories.length > 1) {
          // at least one non-mandatory cookie accepted
          location.reload();
        }
      },

      onAccept: function (cookie) {
        // triggered each page load if the cookies are accepted
      },

      onChange: function (cookie, changed_preferences) {
        // ...
      },

      languages: {
        bg: {
          consent_modal: {
            title: 'Ние използваме бисквитки!',
            description:
              'Altin.bg използва бисквитки, за да персонализира и подобри Вашето изживяване в сайта. Избирайки бутона “Приеми всички”, можем да Ви предоставим по-добро изживяване. Блокирането на определени типове бисквитки ще окаже влияние върху начина, по който Ви доставяме персонализирано съдържание. Ако искате да научите повече, моля, прочетете нашата <a class="cc-link" href="' +
              cookiesHref +
              '">Политика за бисквитките</a>.',
            primary_btn: {
              text: 'Приеми всички',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Настройки',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Предпочитания за бисквитки',
            save_settings_btn: 'Запази промените',
            accept_all_btn: 'Приеми всички',
            //   reject_all_btn: 'Reject all',
            close_btn_label: 'Затвори',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' },
            ],
            blocks: [
              {
                title: '',
                description: '',
              },
              {
                title: 'Основни',
                description:
                  'Тези бисквитки са от съществено значение за правилното функциониране на този уебсайт. Без тези бисквитки уебсайтът няма да работи правилно.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: 'Анализ',
                description:
                  'Уеб контролиране, проследяване на реализациите на маркетингови инструменти.',
                toggle: {
                  value: 'analytics', // your cookie category
                  enabled: true,
                  readonly: false,
                },
                // cookie_table: [             // list of all expected cookies
                //     {
                //         col1: '^_ga',       // match all cookies starting with "_ga"
                //         col2: 'google.com',
                //         col3: '2 years',
                //         col4: 'description ...',
                //         is_regex: true
                //     },
                //     {
                //         col1: '_gid',
                //         col2: 'google.com',
                //         col3: '1 day',
                //         col4: 'description ...',
                //     }
                // ]
              },
              // {
              //   title: 'Реклама',
              //   description:
              //     'Тези бисквитки събират информация за това как използвате уебсайта, кои страници сте посетили и върху кои връзки сте кликнали. Всички данни са анонимни и не могат да бъдат използвани за да бъдете идентифицирани.',
              //   toggle: {
              //     value: 'targeting',
              //     enabled: true,
              //     readonly: false,
              //   },
              // },
              {
                title: 'Повече информация',
                description:
                  'За всякакви въпроси във връзка с нашата политика относно бисквитките и вашия избор, моля <a class="cc-link" href="' +
                  contactsHref +
                  '">свържете се с нас</a>.',
              },
            ],
          },
        },
      },
    });

  }

  // function askCookiesPermissionOld() {
  //     window.cookieconsent.initialise({
  //         "palette": {
  //           "popup": {
  //             "background": "#d8b44e",
  //             "text": "#000"
  //           },
  //           "button": {
  //             "background": "#000",
  //             "text": "#fff"
  //           }
  //         },
  //         "theme": "classic",
  //         "position": "bottom",
  //         "content": {
  //           "message": "Продължавайки да използвате този сайт, вие се съгласявате с използването на бисквитки.",
  //           "dismiss": "Разбрах!",
  //           "link": "Още ...",
  //           "href": "https://altin.bg/%D0%BE%D0%B1%D1%89%D0%B8-%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F"
  //         }
  //     });
  // }

  return {
    init: init,
    destroy: destroy
  };

})(jQuery);

export default cookies;