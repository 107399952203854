import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 20/08/2024
 *
 * Social media share scripts
 **/

 document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';
        
        /**
         * @created by Zheko Hristov on 20/08/2024
         * @last-updated by Zheko Hristov on 20/08/2024
         *
         * Scripts locales
         **/

         const socialMediaShareControllerLocales = {
            bg: {
                successfully_copied: 'Успешно копиран линк',
                not_successfully_copied: 'Съжаляваме, но нещо се обърка с процеса на копиране. Моля, копирайте линка ръчно',
            },
            en: {
                successfully_copied: 'Successfully copied to the clipboard',
                not_successfully_copied: 'We are sorry, but something went wrong with the copy process. Please copy the link manually',
            },
        };

        const currentLocale = 'bg';

        const successfullyCopied = socialMediaShareControllerLocales[currentLocale].successfully_copied;
        const notSuccessfullyCopied = socialMediaShareControllerLocales[currentLocale].not_successfully_copied;

        /**
         * @created by Zheko Hristov on 20/08/2024
         * @last-updated by Zheko Hristov on 29/08/2024
         *
         * Social media share
         **/

        // facebook share
        $('.js-fb-share').on('click', function () {
            const fbAppId = '291874809421481';
            const shareUrl = $(this).data('url') || window.location.href;
            const encodedShareUrl = encodeURIComponent(shareUrl);
           
            // const facebookShareLink =
            //     'https://www.facebook.com/sharer/sharer.php?display=popup&u=' + encodedShareUrl;
            
            // window.open(
            //     facebookShareLink,
            //     'sharer',
            //     'toolbar=0,status=0,resizable=1,width=626,height=436'
            // );

            // approach from 'spestovnik' project
            const facebookShareLink =
                `https://www.facebook.com/dialog/share?app_id=` + fbAppId + `&display=popup&href=` + encodedShareUrl;
            
            window.open(
                facebookShareLink,
                '',
                'width=800,height=600'
            );
        });

        // copy
        const copyContent = async function () {
            const currentUrl = window.location.href;
            try {
                await navigator.clipboard.writeText(currentUrl);
                Alerts
                    .renderAlert(successfullyCopied, 'success');
            } catch {
                
                Alerts
                    .renderAlert(notSuccessfullyCopied, 'error');
            }
        };

        $('.js-copy-share').on('click', function () {
            copyContent();
        });
    })();
});
