
/**
 * @created by Zheko Hristov on 20/08/2024
 *
 * Product details scripts
 **/

 document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';
        
        /**
         * @created by Zheko Hristov on 20/08/2024
         * @last-updated by Zheko Hristov on 13/12/2024
         *
         * Update details main image source
         **/

        const detailsMainImage = $('.product-details__container--main-image .single-product__container--image img')
        const secondaryImageContainers = $(
            '.js-secondary-images-single'
        );

        if (secondaryImageContainers.length > 0) {
            function showImage(img) {
                img.removeClass('image--hidden');
                secondaryImageContainers.css({'pointer-events': 'auto'});
            }

            // change image source with animation function
            function changeImageSourceWithAnimation(image, source) {
                image.addClass('image--hidden');
                setTimeout(function () {
                    image.attr('src', source);
                    if (image[0].complete) {
                        showImage(image);
                    } else {
                        image.on('load', function () {
                            showImage(image);
                            image.off('load');
                        });
                    }   
                }, 300);
            }

            // update main background image container with image source of the active container
            $('.product-details__container--secondary-images').on(
                'click',
                '.js-secondary-images-single',
                function () {
                    secondaryImageContainers.css({'pointer-events': 'none'});
                    const thisImageContainer = $(this);
                    const thisImageSource = thisImageContainer.attr('data-large-image-source');
                    
                    secondaryImageContainers.removeClass('active');
                    thisImageContainer.addClass('active');

                    changeImageSourceWithAnimation(detailsMainImage, thisImageSource);
                }
            );
        }
    })();
});
