var boricaScripts = (function($) {
  function init(){
    autoSubmitForm();
  }

  function autoSubmitForm() {
    $('#borica-payment-form').submit();
  }

  return {
    init: init
  };

})(jQuery);

export default boricaScripts;