/**
 * @created by Zheko Hristov on 17/08/2021
 *
 * Main site scripts
 **/

 document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Hide page content before page renders to fix the propper opacity effect when changing pages
         **/

        $(
            '.header__container--top, .header__container--middle, .header__container--bottom, .main__container--outer, footer'
        ).removeClass('showed');

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Remove 'fixed' class for propper display the header element container when changing pages with scroll
         **/

        $('.header__container--middle').removeClass('fixed');

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Close the mobile menu if it is not closed after browser back button clicks
         **/

        if ($('.mobile-menu__container--icons .fa-times').hasClass('showed')) {
            $('.mobile-menu__container--icons .fa-times').removeClass('showed');
            $('.mobile-menu__container--icons .fa-bars').addClass('showed');
            $(
                '.header__container--bottom, .main__container--outer, footer, .button--scroll-top'
            ).removeClass('mobile-menu-showed');
        }

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Close search submenu
         **/

        $('.header__container--search-icon').removeClass('clicked');
        $('.middle-header__container--submenu.search-submenu').removeClass('showed');
    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * iOS input zoom disable
         **/

        // function fo setting maximum-scale=1.0 to meta viewport
        // prevent iOS input zoom and not pevent the default zoomin
        function addMaximumScaleToMetaViewport() {
            const el = document.querySelector('meta[name=viewport]');
            if (el !== null) {
                let content = el.getAttribute('content');
                let re = /maximum\-scale=[0-9\.]+/g;
                if (re.test(content)) {
                    content = content.replace(re, 'maximum-scale=1.0');
                } else {
                    content = [content, 'maximum-scale=1.0'].join(', ');
                }
                el.setAttribute('content', content);
            }
        }

        // funcion for check the device
        function checkIfIsIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        // if the devices are iPad|iPhone|iPod, execute the function only for them,
        // because this function will stop the default zooming on Androd devices
        if (checkIfIsIOS()) {
            addMaximumScaleToMetaViewport();
        }

        /**
         * @created by Zheko Hristov on 02/09/2021
         * @last-updated by Zheko Hristov on 10/05/2022
         *
         * Main content minimum height
         **/

         function mainContainerMinHeight() {
            const windowHeight = $(window).height();
            const headerContainerHeight = $('header').height();
            const footerContainerHeight = $('footer').innerHeight();
            const calculatedMainContainerMinHeight =
                windowHeight - (headerContainerHeight + footerContainerHeight);

            $('.main__container--outer').css({
                'min-height':
                    calculatedMainContainerMinHeight > 0 ? calculatedMainContainerMinHeight : 0,
            });
        }

        mainContainerMinHeight();

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Show page content with some animation and delay
         **/

        setTimeout(function () {
            // remove loader container
            $('.loader__container--outer').removeClass('showed');
            $(
                '.header__container--top, .header__container--middle, .header__container--bottom, .main__container--outer, footer'
            ).addClass('showed');
        }, 350);

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Mobile menu scripts
         **/

        const openMobileMenuIcon = $('.mobile-menu__container--icons .fa-bars');
        const closeMobileMenuIcon = $('.mobile-menu__container--icons .fa-times');
        const mainMenuContainer = $('.header__container--bottom');

        // open mobile menu
        openMobileMenuIcon.on('click', function () {
            mainMenuContainer.css({
                transition: 'transform 0.2s linear',
            });
            $(this).removeClass('showed');
            closeMobileMenuIcon.addClass('showed');
            mainMenuContainer.addClass('mobile-menu-showed');
            $('.main__container--outer, footer, .button--scroll-top').addClass(
                'mobile-menu-showed'
            );
            closeMobileMenuIcon.css({ 'pointer-events': 'none' });
            setTimeout(function () {
                closeMobileMenuIcon.css({ 'pointer-events': 'auto' });
                mainMenuContainer.css({
                    transition: 'none',
                });
            }, 200);
        });

        // close mobile menu
        closeMobileMenuIcon.on('click', function () {
            mainMenuContainer.css({
                transition: 'transform 0.2s linear',
            });
            $(this).removeClass('showed');
            openMobileMenuIcon.addClass('showed');
            mainMenuContainer.removeClass('mobile-menu-showed');
            openMobileMenuIcon.css({ 'pointer-events': 'none' });
            setTimeout(function () {
                mainMenuContainer.css({
                    transition: 'none',
                });
                $('.main__container--outer, footer, .button--scroll-top').removeClass(
                    'mobile-menu-showed'
                );
                openMobileMenuIcon.css({ 'pointer-events': 'auto' });
            }, 200);
        });

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Open/close account and search submenus
         **/

        const headerSearchIconContainer = $('.header__container--search-icon');
        const searchSubmenuContainer = $('.middle-header__container--submenu.search-submenu');

        // show/hide search submenu
        headerSearchIconContainer.on('click', function () {
            if (headerSearchIconContainer.hasClass('clicked')) {
                headerSearchIconContainer.removeClass('clicked');
                searchSubmenuContainer.removeClass('showed');
            } else {
                headerSearchIconContainer.addClass('clicked');
                searchSubmenuContainer.addClass('showed');
            }
        });

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Toggle showing scroll to top button
         **/

        function showHideScrollTopButton() {
            if ($(window).scrollTop() > 0) {
                $('.button--scroll-top').addClass('showed');
            } else {
                $('.button--scroll-top').removeClass('showed');
            }
        }

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Scroll to top
         **/

        $('.button--scroll-top').on('click', function () {
            $('body, html').animate(
                {
                    // animate your right div
                    scrollTop: 0,
                },
                400,
                'linear'
            );
        });

        /**
         * @created by Zheko Hristov on 13/08/2024
         * @last-updated by Zheko Hristov on 13/08/2024
         *
         * Contact us page show/hide offices
         **/

        $('.contacts__container--office-names').on('click', '.single-office__text--name', function () {
            const thisOfficeNameContainer = $(this);
            
            if (thisOfficeNameContainer.hasClass('active')) {
                return;
            }

            const thisOfficeNameContainerIndex = thisOfficeNameContainer.index();
            $('.single-office__text--name.active, .single-office__container--details.active, .single-office__container--map.active')
                .removeClass('active');

            thisOfficeNameContainer.addClass('active');
            $(`.single-office__container--details:nth-of-type(${thisOfficeNameContainerIndex + 1}), .single-office__container--map:nth-of-type(${thisOfficeNameContainerIndex + 1})`)
                .addClass('active');

        });

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Header middle container add and remove class '.fixed'
         **/

        function headerMiddleContainerFixedOnOff() {
            if (window.innerWidth >= 801) {
                if (
                    $('header').height() - $('.header__container--bottom').innerHeight() <=
                    $(window).scrollTop()
                ) {
                    $('.header__container--middle').addClass('fixed');
                } else {
                    $('.header__container--middle').removeClass('fixed');
                }
            }
        }

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 05/08/2024
         *
         * Scroll event
         **/

         $(window).on('scroll', function () {
            headerMiddleContainerFixedOnOff();
            showHideScrollTopButton();
        });

        /**
         * @created by Zheko Hristov on 05/08/2024
         * @last-updated by Zheko Hristov on 06/08/2024
         *
         * Resize event
         **/

        let windowWidth = $(window).width();

        $(window).on('resize', function () {
            // fix issue with showing/hiding address bar on mobile devices on scroll is firing the resize event
            if (windowWidth !== $(window).width()) {
                setTimeout(function () {
                    headerMiddleContainerFixedOnOff();
                    mainContainerMinHeight();
                }, 250);
            }

            windowWidth = $(window).width();
        });
    })();
});
